import React from 'react'
import ContactSection from '../components/ContactSection'
import PageSpace from '../components/PageSpace'
import ParagraphText from '../components/typography/ParagraphText'
import { RegularTitle, SectionTitle } from '../components/typography/Title'
function ContactPage() {
    return (
        <PageSpace top={100} bottom={100}>
            <div className='container' style={{textAlign: 'center'}}>
                <SectionTitle>How can we help?</SectionTitle>
                <RegularTitle>Fill out the form below to drop us an email</RegularTitle>
                <br/>
            </div>
            <div className='container'>
            <ContactSection/>
            </div>
        </PageSpace>
    )
}

export default ContactPage
